@if (user(); as user) {
<div (click)="openMenu()" class="accountbutton">
    <div class="accountbutton__icon">
        <img [src]="user.avatar_url" alt="" width="30" height="30" class="accountbutton__icon__image">
        <app-subscription-indicator-chip [type]="currentSubscription().subscriptionType?.name" size="tiny"
            class="accountbutton__icon__subscription"></app-subscription-indicator-chip>
    </div>

    @if (isMobile() === false) {
    <div class="accountbutton__text">
        {{user.name || user.email}}
    </div>
    }
</div>

@if (showAccountMenu()) {
<div class="accountbutton__backdrop" (mousedown)="closeMenu()" (click)="closeMenu()">
</div>
<div [@trayAnimationRight] class="accountbutton__accountmenu">
    <div class="accountbutton__accountmenu__close" (click)="closeMenu()">
        <svg height="16" width="16" class="icon">
            <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-x"></use>
        </svg>
    </div>
    <div class="accountbutton__accountmenu__header">
        <div class="accountbutton__accountmenu__header__icon">
            <img [src]="user?.avatar_url" alt="" width="55" height="55"
                class="accountbutton__accountmenu__header__icon__image">
            <app-subscription-indicator-chip [type]="currentSubscription().subscriptionType.name" size="tiny"
                class="accountbutton__accountmenu__header__subscription"></app-subscription-indicator-chip>
        </div>
        <div>
            <div class="accountbutton__accountmenu__header__name">{{user.name}}</div>
            <div class="accountbutton__accountmenu__header__email">{{user.email}}</div>
        </div>
    </div>

    <div class="accountbutton__accountmenu__content">
        @defer(when showAccountMenu()) {
        <app-menu menuType="accountmenu-loggedin" (closeMenuEmitter)="closeMenu()"
            [menuItems]="loggedInMenuItems()"></app-menu>
        }
    </div>
</div>
}

} @else {
<div (click)="openMenu()" class="accountbutton">
    <svg height="16" width="16" class="icon" class="accountbutton__usericon icon">
        <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-user"></use>
    </svg>
    @if (isMobile()=== false) {
    <span class="accountbutton__text">
        Mijn account
    </span>
    }
</div>


@if (showAccountMenu()) {
<div class="accountbutton__backdrop" (mousedown)="closeMenu()" (click)="closeMenu()">
</div>
<div class="accountbutton__accountmenu">
    <div class="accountbutton__accountmenu__close" (click)="closeMenu()">
        <svg height="16" width="16" class="icon">
            <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-x"></use>
        </svg>
    </div>
    <div (click)="login()" class="accountbutton__accountmenu__header">
        <svg style="color:black" height="55" width="55"
            class="icon accountbutton__accountmenu__header__avatar accountbutton__accountmenu__header__avatar--logged-out">
            <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-user"></use>
        </svg>
        <div class="accountbutton__accountmenu__header__name">U bent niet ingelogd</div>
    </div>

    <div class="accountbutton__accountmenu__content">
        @defer(when showAccountMenu()) {
        <app-menu menuType="accountmenu-loggedout" (closeMenuEmitter)="closeMenu()"
            [menuItems]="loggedOutMenuItems()"></app-menu>
        }

    </div>
</div>
}

}