import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-header-menutray',
  templateUrl: './header-menutray.component.html',
  styleUrl: './header-menutray.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('trayAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 1, overflow: 'hidden' }),
        animate('50ms ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ overflow: 'hidden' }),
        animate('50ms ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class HeaderMenutrayComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter<void>();
  @Input() contentPosition: 'left' | 'center' = 'left';

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) { }


  ngOnInit(): void {
    this.renderer2.addClass(this.document.body, 'header__menutray--' + this.contentPosition);
  }

  ngOnDestroy(): void {
    this.renderer2.removeClass(this.document.body, 'header__menutray--' + this.contentPosition);
  }
}
