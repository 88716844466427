import { UserParcelAttributes } from "shared-types";

export interface GetUserParcelParams {
    organisation_id: string,
    limit: string,
    offset: string,
    sortBy?: string,
    tags?: string[],
    searchTerm?: string,
    minSize?: string,
    maxSize?: string,
    bbox?: string
}

export class UserParcelsStateModel {
    userParcels: UserParcelAttributes[];
    tags: string[];
    selected: string[];
}

export class ListUserParcels {
    static readonly type = '[UserParcels] List';
    constructor(public payload: GetUserParcelParams) {
    }
}

export class CreateUserParcels {
    static readonly type = '[UserParcels] Create';
    constructor(public payload: { parcels: Partial<UserParcelAttributes>[], organisationId: string }) {
    }
}

export class RemoveUserParcels {
    static readonly type = '[UserParcels] Remove';
    constructor(public payload: { ids: string[] }) {
    }
}

export class UpdateUserParcel {
    static readonly type = '[UserParcels] Update';
    constructor(public payload: { id: string, updatedValues: Partial<UserParcelAttributes> }) {
    }
}

export class AddRights {
    static readonly type = '[UserParcels] AddRights';
    constructor(public payload: { id: string }) {
    }
}

export class SelectUserParcels {
    static readonly type = '[UserParcels] SelectUserParcels';
    constructor(public payload: { userParcelIds: string[] }) {
    }
}

export class DeselectUserParcels {
    static readonly type = '[UserParcels] DeselectUserParcels';
    constructor(public payload: { userParcelIds: string[] }) {
    }
}

export class ClearUserParcelSelection {
    static readonly type = '[UserParcels] ClearUserParcelSelection';
    constructor() {
    }
}

export class ListTags {
    static readonly type = '[UserParcels] ListTags';
    constructor(public payload: {
        organisationId: string
    }) {
    }
}

export class ClearUserParcels {
    static readonly type = '[UserParcels] Clear';
    constructor() {
    }
}

export class AppendSingleUserParcel {
    static readonly type = '[UserParcels] AppendSingle';
    constructor(public payload: { userParcelId: string }) {
    }
}

