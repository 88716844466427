import { UserState } from '@app/core/states/user.state';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { OrderItem, OrderItemCreationParams, Product } from '../../interfaces/order.model';
import { CoreModule } from '@app/core/core.module';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { AppState } from '../../states/app.state';
import { AddItemsToCart, RemoveItemFromCart } from '../../states/models/shopping-cart.state.model';
import { CreateUserParcels, RemoveUserParcels, UpdateUserParcel } from '../../states/models/user-parcels.state.model';
import { CreateFile, RemoveFile } from '../../states/models/file.state.model';
import dayjs from 'dayjs';
import { OrganisationState } from '@app/core/states/organisation.state';

declare let gtag: Function;

export const enum CustomGoogleEvent {
  manage_parcel = 'manage_parcel',
  download = 'download',
  measure = 'measure',
  layers = 'layers',
  additional_info = 'additional_info',
  bestemmingsplan_infocard = 'bestemmingsplan_infocard',
  subscription_payment_information = 'subscription_payment_information',
  view_subscription_options = 'view_subscription_options',
  add_subscription_to_cart = 'add_subscription_to_cart',
  purchase_subscription = 'purchase_subscription',
  export = 'export',
  shadow_map = 'shadow_map',
  dso = 'dso',
  change_monitor = 'change_monitor',
  survey = 'survey',
  view_header_menu = 'view_header_menu',
};
// -1. 
// 0. Histogram van maandelijkse downloadaantallen per gebruiker / ge-exporteerde percelen per gebruiker
// 1. Welk percentage sleeper-subscriptions hebben wij? En wie zijn dit?
// 2. Gemiddelde per gebruiker / verhouding tov gemiddelde van alle gebruikers 
// 3. 

/* 
* Hoeveel dagen per maand gebruikt een gebruiker de applicatie?
* Meten bij export: 
*  - Aantal percelen dat wordt ge-exporteerd.
*  - Bestandsformaat
*  - Wel of geen eigendomsinformatie
*  - Zoeken
*  - Klikken op de kaart
*
* Meten: 
* - Als er nieuwe meting begonnen wordt (als het kan bijhouden of dit automatisch is)
* - Als er een meting wordt gedownload (welk bestandstypen).
* Download: 
* - Bestandsformaat + settings + oppervlakte
* Omgevingsplannen + bestemmingsplannen: 
* - Aantal pageviews onder /omgevingswet
* Korting
* - Hoeveel rapporten worden er gedownload met korting? (kijken of dit uit purchase te halen is)
* Lagen: 
- Heb je gewisselt tussen luchtfotoweergave
* Extra info
  - Hoeveel keer is er op extra info geklikt?

  Percelen
  - Hoeveel percelen zijn er bekeken?
  Adressen
  - Hoeveel adressen zijn er bekeken?
  
  Percelen opslaan & beheren
  - Alle huidige CRUD events + aantal keer dat iemand /mijn-percelen bekijkt

  Notificaties
  t.b.d.

  Koppeling met externe websites
  - Kijken hoeveel external-links er zijn aangeklikt

  Betalen op rekening
  - Halen uit 'purchase' event -> bijhouden 'op rekening type'

  Eigen velden toevoegen

  API
*/

interface ListItem {
  item_id: string; // The ID of the item
  item_name: string; // The name of the item

  affiliation?: string; // A product affiliation to designate a supplying company or brick and mortar store location
  coupon?: string; // The coupon name/code associated with the item

  // Event-level and item-level coupon parameters are independent
  discount?: number; // The monetary discount value associated with the item
  index?: number; // The index/position of the item in a list
  item_brand?: string; // The brand of the item
  item_category?: string; // The category of the item. If used as part of a category hierarchy or taxonomy, this will be the first category
  item_category2?: string; // The second category hierarchy or additional taxonomy for the item
  item_category3?: string; // The third category hierarchy or additional taxonomy for the item
  item_category4?: string; // The fourth category hierarchy or additional taxonomy for the item
  item_category5?: string; // The fifth category hierarchy or additional taxonomy for the item
  item_list_id?: string; // The ID of the list in which the item was presented to the user

  // If set, event-level item_list_id is ignored
  // If not set, event-level item_list_id is used, if present
  item_list_name?: string; // The name of the list in which the item was presented to the user

  // If set, event-level item_list_name is ignored
  // If not set, event-level item_list_name is used, if present
  item_variant?: string; // The item variant or unique code or description for additional item details/options
  location_id?: string; // The physical location associated with the item (e.g., the physical store location). It's recommended to use the Google Place ID that corresponds to the associated item. A custom location ID can also be used

  // Note: `location id` is only available at the item-scope
  price?: number; // The monetary price of the item, in units of the specified currency parameter
  quantity?: number; // Item quantity. If not set, quantity is set to 1
  currency?: string;
}

@Injectable({
  providedIn: CoreModule
})
export class GoogleAnalyticsService {
  /* Google Analytics is loaded in main.ts */
  // Only run this when there is a google analytics present and when on a production build.
  // Register the page at which someone enters

  // Documentation on what the parameters are can be found here: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag

  constructor(
    private router: Router,
    private store: Store,
    private actions: Actions,
  ) {
    if (this.shouldSend() === false) {
      return;
    }


    /* Enables Analytics */
    gtag('config', environment.googleAnalyticsTrackingId, { anonymize_ip: true, send_page_view: false, allow_enhanced_conversions: true });

    // Add consent
    this.store.select(AppState.googleAdsConsent).pipe(
      filter(consentGiven => consentGiven === true)
    ).subscribe((consentGiven) => {
      gtag('consent', 'update', {
        'ad_storage': 'granted',
        ad_personalization: 'granted',
        ad_user_data: 'granted'
      });
    });

  }

  public track() {
    if (!this.shouldSend()) {
      return;
    }

    // Normal pageviews.
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.set('content_group', this.urlToContentGroup(event.urlAfterRedirects));
      this.emitPageview();
      // Tegen beter weten in, maar het proberen waard.
    });


    // Set details of the users.
    this.store.select(UserState.user).subscribe(user => {
      const subscription = this.store.selectSnapshot(OrganisationState.active_subscription);
      const organisation = this.store.selectSnapshot(OrganisationState.organisation);
      this.set('user_id', user?.auth0Id);

      if (subscription) {
        let accountAge = '';
        let account_start_date = '';
        const accountSince = dayjs(dayjs()).diff(user?.createdAt, 'week');
        accountAge = this.classifyAccountAge(accountSince);
        account_start_date = dayjs(user?.createdAt).format('YYYY-MM');
        this.set('user_properties', { subscription: subscription?.subscriptionType?.name || 'none', isPrivate: organisation.is_private, sector: organisation.sector, accountAge, account_start_date });
      }

    });

    // Ecommerce
    // Add item to cart.
    this.actions.pipe(ofActionSuccessful(AddItemsToCart)).subscribe((action: AddItemsToCart) => {
      const items = this.orderItemToListItem(action.payload.orderItems)
      this.event('add_to_cart', {
        items: items
      });

    });

    // Remove item from cart.
    this.actions.pipe(ofActionSuccessful(RemoveItemFromCart)).subscribe((action: RemoveItemFromCart) => {
      const items = this.orderItemToListItem([action.payload.orderItem]);
      this.event('remove_from_cart', {
        items
      })
    });


    // Custom event tracking(!)
    // Manage_parcel event
    this.actions.pipe(ofActionSuccessful(CreateUserParcels)).subscribe((action: CreateUserParcels) => {
      this.event(CustomGoogleEvent.manage_parcel, {
        type: 'create'
      })
    });
    this.actions.pipe(ofActionSuccessful(RemoveUserParcels)).subscribe((action: RemoveUserParcels) => {
      this.event(CustomGoogleEvent.manage_parcel, {
        type: 'remove'
      })
    });
    this.actions.pipe(ofActionSuccessful(UpdateUserParcel)).subscribe((action: UpdateUserParcel) => {
      this.event(CustomGoogleEvent.manage_parcel, {
        type: 'edit'
      })
    });
    this.actions.pipe(ofActionSuccessful(CreateFile)).subscribe((action: CreateFile) => {
      this.event(CustomGoogleEvent.manage_parcel, {
        type: 'upload_file'
      })
    });
    this.actions.pipe(ofActionSuccessful(RemoveFile)).subscribe((action: RemoveFile) => {
      this.event(CustomGoogleEvent.manage_parcel, {
        type: 'remove_file'
      })
    });

  }


  // Native function
  public event(eventName: string, opts?: { [key: string]: any }) {
    if (!this.shouldSend()) {
      return;
    }
    gtag("event", eventName, {
      ...opts,
      content_group: this.urlToContentGroup(window.location.pathname) // Content group is added to each event manually, because calling set doesn't seem to properly set the content group on the gtag properly.
      // Or at least, it doesn't add it automatically to every event.
    });
  }

  // Native function
  public set(parameter: string, value: any) {
    if (!this.shouldSend()) {
      return;
    }
    gtag("set", parameter, value);
  }

  public emitViewItem(product: Product, shownFrom: string) {
    if (!this.shouldSend()) {
      return;
    }

    // This should only be logged from the product-detail component.
    this.event('view_item', {
      items: this.productToListItem([
        product
      ], shownFrom)
    });

  }



  private orderItemToListItem(orderItems: OrderItemCreationParams[]) {
    const items = orderItems.map((orderItem, index) => {
      const item: ListItem = {
        item_id: `${orderItem.product.id}`,
        item_name: orderItem.product.name,
        affiliation: orderItem.data.addedFrom,
        price: +orderItem.originalPrice,
        discount: +orderItem.originalPrice - +orderItem.sellPrice,
        quantity: orderItem.data?.selectedOption?.quantity || 1,
        index: index + 1,
        currency: 'EUR'
      }
      return item;
    });


    return items;
  }

  private productToListItem(products: Product[], shownFrom: string) {
    const items = products.map((product, index) => {
      const item: ListItem = {
        item_id: `${product.id}`,
        item_name: product.name,
        affiliation: shownFrom,
        price: +product.price,
        discount: 0,
        quantity: 1,
        index: index + 1,
        currency: 'EUR'
      }
      return item;
    });


    return items;
  }

  public emitViewCart(orderPriceSum: number, products: Product[]) {
    this.event('view_cart', {
      value: orderPriceSum,
      currency: 'EUR',
      items: this.productToListItem(products, '')
    });
  }

  public emitViewItemList(products: Product[], shownFrom: string) {
    this.event('view_item_list', {
      items: this.productToListItem(products, shownFrom)
    });
  }


  // Checkout step 1
  public beginCheckout(orderItems: OrderItemCreationParams[], totalPrice: number) {
    const items = this.orderItemToListItem(orderItems);
    if (!this.shouldSend()) {
      return;
    }
    this.event('begin_checkout', {
      items: items,
      value: totalPrice,
      currency: 'EUR'
    });

  }
  // Checkout step 2
  public addShippingInfo(orderItems: OrderItemCreationParams[], totalPrice: number) {
    this.event('add_shipping_info', {
      currency: 'EUR',
      value: totalPrice,
      items: this.orderItemToListItem(orderItems)
    });
  }
  // Checkout step 3
  public addPaymentInfo(orderItems: OrderItemCreationParams[], totalPrice: number, method: string) {
    this.event('add_payment_info', {
      value: totalPrice,
      currency: 'EUR',
      items: this.orderItemToListItem(orderItems),
      method: method
    });

  }
  // Checkout step 4
  public emitPurchase(totalPrice: string, orderReference: string, orderItems: OrderItem[]) {
    const items = this.orderItemToListItem(orderItems);
    const totalPriceForTaxCalculation = parseFloat(totalPrice);
    const tax = totalPriceForTaxCalculation - (totalPriceForTaxCalculation / 1.21);

    if (!this.shouldSend()) {
      return;
    }


    this.event('purchase', {
      transaction_id: orderReference,
      value: +totalPrice,
      tax: +tax,
      currency: 'EUR',
      shipping: 0,
      items: items,
    });

  }

  public addUserDataForEnhancedConversions(email: string) {
    if (!this.shouldSend()) {
      return;
    }


    // This one is required for the normal 'purchase' event/
    // @ts-ignore 
    dataLayer.push({
      'event': 'enhanced_conversion',
      enhancedConversions: {
        email
      }
    })
  }

  private urlToContentGroup(url: string) {
    const contentGroups = [
      { regex: /^\/support/, value: 'Support' },
      { regex: /^\/afrekenen/, value: 'Checkout' },
      { regex: /^\/woonplaatsen/, value: 'Kennisbank - woonplaatsen' },
      { regex: /^\/kadastrale-gemeenten/, value: 'Kennisbank - Kadastrale gemeenten' },
      { regex: /^\/gemeenten/, value: 'Kennisbank - gemeenten' },
      { regex: /^\/wijken/, value: 'Kennisbank - wijken' },
      { regex: /^\/buurten/, value: 'Kennisbank - buurten' },
      { regex: /^\/nieuws/, value: 'Nieuws' },
      { regex: /^\/adres/, value: 'Adrespagina' },
      { regex: /^\/profiel\/mijn-percelen/, value: 'Profiel - mijn percelen' },
      { regex: /^\/profiel\/beheer/, value: 'Profiel - beheer' },
      { regex: /^\/profiel\/mijn-bestellingen/, value: 'Profiel - mijn bestellingen' },
      { regex: /^\/profiel\/mijn-alerts/, value: 'Profiel - mijn alerts' },
      { regex: /^\/profiel/, value: 'Profiel' },
      { regex: /^\/upgrade/, value: 'Upgrade' },
      { regex: /^\/veelgestelde-vragen/, value: 'FAQ' },
      { regex: /^\/producten/, value: 'Product-gebaseerde-winkel' },
      { regex: /^\/handleiding/, value: 'Handleiding' },
      { regex: /^\/export/, value: 'Export' },
      { regex: /^\/begrippen/, value: 'Kennisbank - begrippen' },
      { regex: /^\/woz/, value: 'WOZ' },
      { regex: /^\/maatwerk/, value: 'Maatwerk' },
      { regex: /^\/over-ons/, value: 'Over ons' },
      { regex: /^\/gebeurtenissen/, value: 'Gebeurtenissen' },
      { regex: /^\/omgevingswet/, value: 'Omgevingswetpagina' },
      { regex: /^\/kaart\/perceel/, value: 'Infocard - perceel' },
      { regex: /^\/kaart\/adres/, value: 'Infocard - adres' },
      { regex: /^\/winkel\/perceel/, value: 'Perceelwinkel' },
      { regex: /(^\/$)|(^\/kaart)/, value: 'Perceelkaart' },
    ];


    const contentGroup = contentGroups.find(group => url.match(group.regex) !== null)?.value || 'Niet gespecificeerd';
    return contentGroup;
  }

  public emitPageview() {
    if (!this.shouldSend()) {
      return;
    }

    this.event('page_view', {
      page_title: document.title,
      page_location: window.location.href
    })
  }


  public get hasGoogleAnalyticsTrackingId() {
    return (
      environment.googleAnalyticsTrackingId !== null &&
      environment.googleAnalyticsTrackingId !== '' &&
      environment.googleAnalyticsTrackingId !== undefined
    );
  }

  private shouldSend(): boolean {
    let answer = false;
    if (environment.enableGoogleAnalytics && this.hasGoogleAnalyticsTrackingId) {
      answer = true;
    }
    return answer;
  }



  public addScriptTag() {
    if (this.shouldSend() === false) {
      return;
    }

    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
      // @ts-ignore
      j.async = true;
      // @ts-ignore
      j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-PFZ6S7Z');
  }

  private classifyAccountAge(accountAgeInWeeks: number) {
    if (accountAgeInWeeks <= 1) {
      return 'less than 1 week';
    } else if (accountAgeInWeeks > 1 && accountAgeInWeeks <= 4) {
      return '1 week to 1 month'
    } else if (accountAgeInWeeks > 4 && accountAgeInWeeks <= 12) {
      return '1 month to 3 months'
    } else if (accountAgeInWeeks > 12) {
      return 'more than 3 months'
    } else {
      return ''
    }
  }
}
