import { Injectable } from '@angular/core';



import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { CoreModule } from '../core.module';
import { ListSubscriptionTypes } from '../states/models/subscription-type.state.model';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: CoreModule
})
export class SubscriptionTypesResolver  {
  constructor(private store: Store) {}

  async resolve(route: ActivatedRouteSnapshot) {
    return await firstValueFrom(this.store.dispatch(new ListSubscriptionTypes()));
  }
}
