import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action, createSelector } from '@ngxs/store';
import { Product } from '../interfaces/order.model';
import { ProductService } from '../services/product.service';
import { ListProducts, PRODUCT_SORT_OPTIONS, ProductsStateModel, SetActiveProductCategory, SetSortOption } from './models/products.state.model';
import { firstValueFrom } from 'rxjs';



@State<ProductsStateModel>({
  name: 'products',
  defaults: {
    products: [],
    activeProductCategory: null,
    activeSortOption: PRODUCT_SORT_OPTIONS[0],
    sortOptions: PRODUCT_SORT_OPTIONS
  }
})
@Injectable()
export class ProductsState {
  // Return all products
  @Selector([ProductsState])
  static products(state: ProductsStateModel) {
    return state.products;
  }

  @Selector([ProductsState])
  static categories(state: ProductsStateModel) {
    const categories = state.products.map(product => product.displayCategories);
    const flattenedCategories = categories.flat();
    return [...new Set(flattenedCategories)];
  }

  @Selector([ProductsState])
  static activeProductCategory(state: ProductsStateModel) {
    return state.activeProductCategory;
  }

  @Selector([ProductsState])
  static activeSortOption(state: ProductsStateModel) {
    return state.activeSortOption;
  }


  @Selector([ProductsState.products, ProductsState.activeProductCategory, ProductsState.activeSortOption])
  static filteredAndSortedProducts(products: Product[], activeProductCategory: string | null, activeSortOption: string) {
    const filteredProducts = activeProductCategory === null ? products : products.filter(product => product.displayCategories.includes(activeProductCategory));

    const sortedProducts = filteredProducts.slice().sort((a, b) => {
      switch (activeSortOption) {
        case 'Populariteit':
          return +a.sortOrder - +b.sortOrder;
        case 'Prijs (laag naar hoog)':
          return +a.sellPrice - +b.sellPrice;
        case 'Prijs (hoog naar laag)':
          return +b.sellPrice - +a.sellPrice;
        case 'Naam (A-Z)':
          return a.alias.toLocaleUpperCase().localeCompare(b.alias.toLocaleUpperCase(), 'nl',);
        case 'Naam (Z-A)':
          return b.alias.toLocaleUpperCase().localeCompare(a.alias.toLocaleUpperCase(), 'nl');
        default:
          return 1;
      }
    });
    return sortedProducts;

  }

  // Dynamic selector notation for returning only 1 product
  static getProductByName(name: string) {
    return createSelector(
      [ProductsState.products],
      (products: Product[]) => {
        return products.find(product => product.name === name);
      }
    );
  }

  constructor(private productService: ProductService) { }

  @Action(ListProducts)
  async listProducts(ctx: StateContext<ProductsStateModel>) {
    const products = await firstValueFrom(this.productService.list());
    ctx.patchState({ products });
  }

  @Action(SetActiveProductCategory)
  setActiveProductCategory(ctx: StateContext<ProductsStateModel>, action: SetActiveProductCategory) {
    ctx.patchState({ activeProductCategory: action.payload.category });
  }

  @Action(SetSortOption)
  setSortOrder(ctx: StateContext<ProductsStateModel>, action: SetSortOption) {
    ctx.patchState({ activeSortOption: action.payload.option });
  }

}
