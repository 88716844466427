import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action } from "@ngxs/store";
import { UserService } from '@app/core/services/user.service';
import { ClearUser, GetUser, UpdateUser, UserStateModel } from "./models/user.state.model";
import { StateNames } from "../common/state-names.enum";
import { firstValueFrom } from "rxjs";


@State<UserStateModel>({
    name: StateNames.userv2,
    defaults: null
})
@Injectable()
export class UserState {
    // Return User.
    @Selector([UserState])
    static user(state: UserStateModel) {
        return state;
    }

    @Selector([UserState])
    static primarySubscriptionGoal(state: UserStateModel) {
        return state.primarySubscriptionGoal;
    }

    @Selector([UserState.primarySubscriptionGoal])
    static hasPrimarySubscriptionGoal(primarySubscriptionGoal: string) {
        const noGoalValues = [null, undefined, '', 'no-selection', 'homepage'];
        return noGoalValues.includes(primarySubscriptionGoal) === false;
    }

    constructor(
        private userService: UserService,
    ) { }

    @Action(GetUser)
    async getUser(ctx: StateContext<UserStateModel>, action: GetUser) {
        const user = await firstValueFrom(this.userService.get(action.payload.userAuth0Id));
        ctx.patchState(user);

    }

    @Action(UpdateUser)
    async updateUser(ctx: StateContext<UserStateModel>, action: UpdateUser) {
        const user = await firstValueFrom(this.userService.update(action.payload.userAuth0Id, action.payload.updatedUser));
        ctx.patchState(user);
    }


    @Action(ClearUser)
    clearUser(ctx: StateContext<UserStateModel>) {
        ctx.setState(null);
    }

}
