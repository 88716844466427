import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action, createSelector } from "@ngxs/store";
import { produce } from "immer";
import { AddRights, AppendSingleUserParcel, ClearUserParcels, ClearUserParcelSelection, CreateUserParcels, DeselectUserParcels, ListTags, ListUserParcels, RemoveUserParcels, SelectUserParcels, UpdateUserParcel, UserParcelsStateModel } from "./models/user-parcels.state.model";
import { UserParcelService } from "../services/user-parcel.service";
import { firstValueFrom } from "rxjs";
import { UserParcelDetail } from "../interfaces/user-parcel.model";
import { UserParcelAttributes } from "shared-types";

@State<UserParcelsStateModel>({
    name: 'userParcel',
    defaults: {
        userParcels: [],
        tags: [],
        selected: [],
    }
})
@Injectable()
export class UserParcelState {
    // Return userParcels.
    @Selector([UserParcelState])
    static userParcels(state: UserParcelsStateModel) {
        return state.userParcels;
    }

    // Dynamic selector notation for returning only 1 userParcel
    static getUserParcelByLokaalId(lokaalId: string) {
        return createSelector(
            [UserParcelState.userParcels],
            (userParcels) => {
                const userParcel = userParcels.find(userParcel => userParcel.parcels.map(parcel => parcel.lokaalid).includes(lokaalId));
                return userParcel;
            }
        );
    }

    static getUserParcelById(id: string) {
        return createSelector(
            [UserParcelState.userParcels],
            (userParcels) => {
                const userParcel = userParcels.find(userParcel => userParcel.id === id) as UserParcelDetail;
                return userParcel;
            }
        );
    }

    @Selector([UserParcelState])
    static selectedUserParcelIds(state: UserParcelsStateModel) {
        return state.selected;
    }

    @Selector([UserParcelState.userParcels, UserParcelState.selectedUserParcelIds])
    static selectedUserParcels(userParcels: UserParcelAttributes[], selectedIds: string[]) {
        return userParcels.filter(userParcel => selectedIds.includes(userParcel.id));
    }

    static isSelected(id: string) {
        return createSelector(
            [UserParcelState.selectedUserParcelIds],
            (ids: string[]) => {
                return ids.includes(id);
            }
        );
    }

    @Selector([UserParcelState])
    static tags(state: UserParcelsStateModel) {
        return state.tags;
    }

    constructor(private userParcelService: UserParcelService) { }

    @Action(ListUserParcels)
    async listUserParcels(ctx: StateContext<UserParcelsStateModel>, action: ListUserParcels) {
        const userParcels = await firstValueFrom(this.userParcelService.listParcels(action.payload));

        // If offset is 0, replace the current userParcels with the new userParcels.
        if (action.payload.offset === '0') {
            return ctx.patchState({
                userParcels: userParcels.rows,
                selected: []
            });
        }

        // If offset is > 0, we need to append them.
        const nextState = produce(ctx.getState().userParcels, draft => {
            for (const newUserParcel of userParcels.rows) {
                const index = draft.findIndex(userParcel => userParcel.id === newUserParcel.id);
                if (index === -1) {
                    draft.push(newUserParcel);
                } else {
                    draft[index] = newUserParcel;
                }
            }
        });

        ctx.patchState({ userParcels: nextState });
    }

    @Action(CreateUserParcels)
    async createUserParcel(ctx: StateContext<UserParcelsStateModel>, action: CreateUserParcels) {

        const createdParcels = await firstValueFrom(this.userParcelService.addParcels(action.payload.organisationId, action.payload.parcels));
        const nextState = produce(ctx.getState().userParcels, draft => {
            draft.push(...createdParcels);
        });
        ctx.patchState({ userParcels: nextState });

    }

    @Action(UpdateUserParcel)
    async updateUserParcel(ctx: StateContext<UserParcelsStateModel>, action: UpdateUserParcel) {
        const updatedParcel = await firstValueFrom(this.userParcelService.updateParcel(action.payload.id, action.payload.updatedValues));
        const nextState = produce(ctx.getState().userParcels, draft => {
            const index = draft.findIndex(userParcel => userParcel.id === updatedParcel.id);
            draft[index] = {
                ...draft[index],
                ...updatedParcel
            };
        });
        ctx.patchState({ userParcels: nextState });
    }

    @Action(AddRights)
    async addRights(ctx: StateContext<UserParcelsStateModel>, action: AddRights) {
        const updatedParcel = await firstValueFrom(this.userParcelService.addRights(action.payload.id));

        const nextState = produce(ctx.getState().userParcels, draft => {
            const index = draft.findIndex(userParcel => userParcel.id === updatedParcel.id);
            draft[index] = {
                ...draft[index],
                ...updatedParcel
            };
        });
        ctx.patchState({ userParcels: nextState });
    }

    @Action(RemoveUserParcels)
    async removeUserParcels(ctx: StateContext<UserParcelsStateModel>, action: RemoveUserParcels) {
        for (const id of action.payload.ids) {
            await firstValueFrom(this.userParcelService.removeParcel(id));
        }

        const filteredParcels = ctx.getState().userParcels.filter(userParcel => !action.payload.ids.includes(userParcel.id));
        const filteredSelected = ctx.getState().selected.filter(id => !action.payload.ids.includes(id));
        ctx.patchState({
            userParcels: filteredParcels,
            selected: filteredSelected
        });
    }


    @Action(AppendSingleUserParcel)
    async appendSingleUserParcel(ctx: StateContext<UserParcelsStateModel>, action: AppendSingleUserParcel) {
        const newUserParcel = await firstValueFrom(this.userParcelService.getParcel(action.payload.userParcelId));
        const nextState = produce(ctx.getState().userParcels, draft => {
            const index = draft.findIndex(userParcel => newUserParcel.id === userParcel.id);

            if (index === -1) {
                draft.push(newUserParcel);
            } else {
                draft[index] = newUserParcel;
            }
        });

        ctx.patchState({ userParcels: nextState });

    }

    @Action(ListTags)
    async listTags(ctx: StateContext<UserParcelsStateModel>, action: ListTags) {
        const tags = await firstValueFrom(this.userParcelService.listTags(action.payload.organisationId));
        ctx.patchState({ tags: tags });
    }


    @Action(ClearUserParcels)
    clearUserParcels(ctx: StateContext<UserParcelsStateModel>) {
        ctx.patchState({ userParcels: [], tags: [], selected: [] });
    }

    @Action(SelectUserParcels)
    selectUserParcels(ctx: StateContext<UserParcelsStateModel>, action: SelectUserParcels) {
        /**
         * This function uses the `produce` function from the `immer` library to create an immutable
         * update to the state. It utilizes a `Set` to efficiently check for the existence of IDs
         * before adding them to the draft state.
         */
        const newIds = produce(ctx.getState().selected, draft => {
            const existingIds = new Set(draft);
            for (const id of action.payload.userParcelIds) {
                if (!existingIds.has(id)) {
                    draft.push(id);
                    existingIds.add(id);
                }
            }
        });

        ctx.patchState({ selected: newIds });

    }

    @Action(DeselectUserParcels)
    deselectUserParcels(ctx: StateContext<UserParcelsStateModel>, action: DeselectUserParcels) {

        const userParcelIdsSet = new Set(action.payload.userParcelIds);
        const newIds = ctx.getState().selected.filter(id => !userParcelIdsSet.has(id));
        ctx.patchState({ selected: newIds });


    }

    @Action(ClearUserParcelSelection)
    clearUserParcelSelection(ctx: StateContext<UserParcelsStateModel>, action: ClearUserParcelSelection) {
        ctx.patchState({ selected: [] });

    }

}



