import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { CoreModule } from '../core.module';
import { Store } from '@ngxs/store';
import { GetUser } from '../states/models/user.state.model';
import { UserState } from '../states/user.state';
import { AuthState } from '../states/auth.state';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: CoreModule
})
export class UserResolver {
  constructor(
    public store: Store,
  ) { }

  async resolve(route: ActivatedRouteSnapshot) {
    //TODO implement user profile fetch
    const auth0Id = this.store.selectSnapshot(AuthState.user_id);
    await firstValueFrom(this.store.dispatch(new GetUser({ userAuth0Id: auth0Id })));
    return this.store.selectSnapshot(UserState.user);
  }
}
