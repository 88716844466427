import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { CoreModule } from '@app/core/core.module';
import { Store } from '@ngxs/store';
import { AuthState } from '../states/auth.state';
import { catchError, switchMap, take, filter } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { GetNewAccessToken } from '../states/models/auth.state.model';
import { Router } from '@angular/router';

@Injectable({
    providedIn: CoreModule
})
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    constructor(private store: Store, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
        const isOurOwnApi = request.url.includes(environment.apiDomain);
        const isNotAuthRequest = !request.url.includes('/auth/');

        if (isAuthenticated && isOurOwnApi && isNotAuthRequest) {
            const accessToken = this.store.selectSnapshot(AuthState.accessToken);
            request = this.addToken(request, accessToken);

            return next.handle(request).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        return this.handle401Error(request, next);
                    }
                    return throwError(error);
                })
            );
        } else {
            return next.handle(request);
        }
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            const refreshToken = this.store.selectSnapshot(AuthState.refreshToken);
            return this.store.dispatch(new GetNewAccessToken({ refreshToken })).pipe(
                switchMap(() => {
                    this.isRefreshing = false;
                    const newAccessToken = this.store.selectSnapshot(AuthState.accessToken);
                    this.refreshTokenSubject.next(newAccessToken);
                    return next.handle(this.addToken(request, newAccessToken));
                }),
                catchError((error) => {
                    this.isRefreshing = false;
                    this.router.navigate(['/account/uitloggen']);
                    return throwError(error);
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap((token) => next.handle(this.addToken(request, token!)))
            );
        }
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}
