import version from '../../../../git-version.json';

export const APP_VERSION = version.version;
export const APP_TITLE = 'KadastraleKaart.com';
export const MIN_MOBILE_WIDTH = 440;
export const MIN_TABLET_WIDTH = 787;
export const MIN_DESKTOP_WIDTH = 981;
export const MIN_DESKTOPXL_WIDTH = 1200;
export const MIN_DESKTOPXXL_WIDTH = 1450;
export const DEFAULT_PAYMENT_TERM_NAME = 'yearly';
export const KADASTER_OWNERSHIP_CODE = '2';