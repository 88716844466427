
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { appConfig } from '@app/app.config';
import { register } from 'swiper/element/bundle';

// Register Swiper custom elements. We do this
// before bootstrapping the Angular application
// so that they're available before any part of
// our application tries rendering them. We need to find a solution to make this not block the entire app.

register();
bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));