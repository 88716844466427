export const MIN_ZOOMLVL_BAGPAND_LAYER = 16;
export const MIN_ZOOMLVL_TRANSACTION_LAYER = 17;

export const LAYER_SETTINGS = [
  {
    name: 'luchtfoto',
    alias: 'Luchtfoto',
    active: true,
    canDisable: true,
    type: 'basemap',
    minZoom: null,
    variations: {
      available: [
        { name: '2024_orthoHR', alias: '2024', requiredPermission: null },
        { name: '2023_orthoHR', alias: '2023', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' },
        { name: '2022_orthoHR', alias: '2022', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' },
        { name: '2021_orthoHR', alias: '2021', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' },
        { name: '2020_ortho25', alias: '2020', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' },
        { name: '2019_ortho25', alias: '2019', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' },
        { name: '2018_ortho25', alias: '2018', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' },
        { name: '2017_ortho25', alias: '2017', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' },
        { name: '2016_ortho25', alias: '2016', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' },
      ],
      selected: '2024_orthoHR'
    },
    thumbnail: 'assets/icons/basemap-luchtfoto@2x.png',
    legend: null
  },
  {
    name: 'kaart',
    alias: 'Kaart',
    active: false,
    canDisable: true,
    type: 'basemap',
    minZoom: null,
    thumbnail: 'assets/icons/basemap-kaart@2x.png',
    legend: null
  },
  {
    name: 'satelliet',
    alias: 'Satelliet (hi-res)',
    active: false,
    canDisable: true,
    type: 'basemap',
    minZoom: null,
    thumbnail: 'assets/icons/basemap-luchtfoto@2x.png',
    legend: null
  },
  {
    name: 'bebouwing',
    alias: 'Bebouwing',
    active: false,
    canDisable: true,
    type: 'overlay',
    minZoom: MIN_ZOOMLVL_BAGPAND_LAYER,
    thumbnail: 'assets/icons/basemap-bebouwing@2x.png',
    legend: 'bebouwing'
  },
  {
    name: 'transacties',
    alias: 'Transacties',
    active: false,
    canDisable: true,
    type: 'overlay',
    minZoom: MIN_ZOOMLVL_TRANSACTION_LAYER,
    thumbnail: 'assets/icons/wms-transacties@2x.webp',
    legend: 'transacties'
  },
  {
    name: 'kadastralekaart',
    alias: 'Percelen',
    canDisable: false,
    active: true,
    type: 'perceel-overlay',
    minZoom: 17,
    thumbnail: 'assets/icons/basemap-user-parcels@2x.png',
    variations: {
      available: [
        { name: 'standaard', alias: 'Status', legend: 'kadastralekaart-standaard', requiredPermission: null },
        { name: 'kwaliteit', alias: 'Grensnauwkeurigheid', legend: 'kadastralekaart-kwaliteit', requiredPermission: 'premium_data:grensnauwkeurigheid_layer:view' },
      ],
      selected: 'standaard'
    },
    legend: null
  },
  {
    name: 'kadastralekaart_als_overlay', // Wanneer de styling van de kadastralekaart (status & nauwkeurigheid) als overlay wordt gezien
    alias: 'Percelen',
    canDisable: true,
    active: true,
    type: 'overlay',
    minZoom: 17,
    thumbnail: 'assets/icons/basemap-user-parcels@2x.png',
    variations: {
      available: [
        { name: 'standaard', alias: 'Status', legend: 'kadastralekaart-standaard', requiredPermission: null },
        { name: 'kwaliteit', alias: 'Grensnauwkeurigheid', legend: 'kadastralekaart-kwaliteit', requiredPermission: 'premium_data:grensnauwkeurigheid_layer:view' },
      ],
      selected: 'standaard'
    },
    legend: null
  },
  {
    name: 'mijn-percelen',
    alias: 'Mijn percelen',
    active: true,
    canDisable: true,
    type: 'perceel-overlay',
    minZoom: null,
    thumbnail: 'assets/icons/basemap-saved-parcels@2x.webp',
    variations: {
      available: [
        { name: 'hoverBeschrijving', alias: 'Geen beschrijving', legend: null, requiredPermission: null },
        { name: 'altijdBeschrijving', alias: 'Met beschrijving', legend: null, requiredPermission: null },
      ],
      selected: 'hoverBeschrijving'
    },
    legend: null
  },
  {
    name: 'eigenaarstype',
    alias: 'Eigenaarstype',
    active: false,
    canDisable: true,
    type: 'overlay',
    minZoom: 16,
    thumbnail: 'assets/icons/basemap-eigenaarstype@2x.png',
    variations: {
      available: [
        { name: 'eigenaarstype_2016', alias: '2016', legend: 'kadastralekaart-eigenaarstype', requiredPermission: 'premium_data:eigenaarstype_layer:view' },
        { name: 'eigenaarstype_2020', alias: '2020', legend: 'kadastralekaart-eigenaarstype', requiredPermission: 'premium_data:eigenaarstype_layer:view' },
      ],
      selected: '2020'
    },
  },
  {
    name: 'natura2000',
    alias: 'Natura 2000',
    active: false,
    canDisable: true,
    type: 'overlay',
    minZoom: 0,
    thumbnail: 'assets/icons/wms-natura2000@2x.webp',
    legend: 'natura2000',
    requiredPermission: 'premium_data:eigenaarstype_layer:view'
  },
  {
    name: 'natuurnetwerk_nederland',
    alias: 'Natuurnetwerk NL',
    active: false,
    canDisable: true,
    type: 'overlay',
    minZoom: 0,
    thumbnail: 'assets/icons/wms-natuurnetwerknederland@2x.webp',
    legend: 'natuurnetwerk_nederland',
    requiredPermission: 'premium_data:eigenaarstype_layer:view'
  },
  {
    name: 'actueel_hoogtebestand_nederland',
    alias: 'Hoogtekaart (AHN)',
    active: false,
    canDisable: true,
    type: 'overlay',
    minZoom: 0,
    thumbnail: 'assets/icons/wms-ahn@2x.webp',
    legend: 'actueel_hoogtebestand_nederland',
    requiredPermission: 'premium_data:eigenaarstype_layer:view'
  },
  {
    name: 'publiekrechtelijke_beperkingen',
    alias: 'Publiekrechtelijke beperkingen',
    active: false,
    canDisable: true,
    type: 'overlay',
    minZoom: 0,
    thumbnail: 'assets/icons/wms-publiekrechtelijke-beperkingen@2x.webp',
    legend: 'publiekrechtelijke_beperkingen',
    requiredPermission: 'premium_data:eigenaarstype_layer:view'
  },
  {
    name: 'bodemkaart',
    alias: 'Bodemkaart',
    active: false,
    canDisable: true,
    type: 'overlay',
    minZoom: 9,
    thumbnail: 'assets/icons/wms-bodemkaart@2x.webp',
    legend: 'bodemkaart',
    requiredPermission: 'premium_data:eigenaarstype_layer:view'
  },
  {
    name: 'gewaspercelen',
    alias: 'Gewaspercelen',
    active: false,
    canDisable: true,
    type: 'overlay',
    minZoom: 12,
    thumbnail: 'assets/icons/wms-gewaspercelen@2x.webp',
    legend: 'gewaspercelen',
    requiredPermission: 'premium_data:eigenaarstype_layer:view'
  },
] as const;

export type LayerSetting = typeof LAYER_SETTINGS[number];
export type LayerSettingName = typeof LAYER_SETTINGS[number]['name'];