export class UserRecordViewStateModel {
    lastVisitedPage: 'table' | 'list' | 'map' = 'table'
}

export class SetLastVisitedPage {
    static readonly type = '[UserRecordView] SetLastVisitedPage';
    constructor(public payload: {
        lastVisitedPage: 'table' | 'list' | 'map'
    }) {
    }
}