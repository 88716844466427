<div class="headerspacer"></div>

<div class="header" [style.top.px]="this.headerDisplacement() - this.initialHeaderHeight">

  @if (isMobile() === true && hideItems() === false) {
  <span matRipple (click)="toggleShowItem('menu')" class="header__button header__button--menu" aria-label="Menu">
    <svg height="20" width="20" class="icon">
      <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-menu"></use>
    </svg>
  </span>
  }

  <a routerLink="/" class="header__logolink">
    <h1 class="header__logo">KadastraleKaart.com</h1>
  </a>

  @if (hideItems()=== false) {
  <div class="header__buttons header__buttons--left">
    @if ((isPro()) === false) {
    <a routerLink="/upgrade" class="header__button">
      Upgrade
    </a>
    }
    <span (click)="toggleShowItem('mainDesktopNavMenu')" class="header__button"
      [class.header__button--open]="showItem() === 'mainDesktopNavMenu'">
      Functies
      <svg height="20" width="20" class="icon">
        <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-chevron-down"></use>
      </svg>
    </span>

    <a routerLink="/producten" class="header__button">
      Webwinkel
    </a>

    <span (click)="toggleShowItem('moreDesktopNavMenu')" class="header__button"
      [class.header__button--open]="showItem() === 'moreDesktopNavMenu'">
      Meer
      <svg height="20" width="20" class="icon">
        <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-chevron-down"></use>
      </svg>
    </span>

  </div>

  }

  <app-spin class="header__spinner" [showSpinner]="showSpinner()"> </app-spin>

  <div class="header__buttons header__buttons--right">
    @if(hideItems() === false) {
    <span class="header__button header__button--account" (click)="toggleShowItem(null)">
      <app-account-button> </app-account-button>
    </span>
    }

    @if(hideItems() === false) {
    <a (click)="toggleShowItem('search')" class="header__button header__button--search">
      <svg height="16" width="16" class="icon">
        <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-search"></use>
      </svg>
    </a>
    }

    @if(hideItems() === false) {
    <a [class.header__button--cart--empty]="itemsInCart() === 0" [routerLink]="['/', 'afrekenen', 'besteloverzicht']"
      class="header__button header__button--cart" aria-label="Winkelwagen">
      <svg height="19" width="19" class="icon">
        <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-shopping-cart"></use>
      </svg>
      @if (itemsInCart() ) {
      <span class="header__button__counter">
        {{ itemsInCart() }}
      </span>
      }

    </a>
    }
  </div>
</div>

@if (showItem() === 'menu') {
<div [@trayAnimationLeft] class="menu-container">
  <div class="menu-container__header">
    <h1 class="menu-container__header__logo">KadastraleKaart.com</h1>
    <div class="menu-container__close" (click)="toggleShowItem(null)">
      <svg height="20" width="20" class="icon">
        <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-x"></use>
      </svg>
    </div>
  </div>

  @defer (when showItem() === 'menu'; prefetch on idle) {
  <app-menu menuType="headermenu-mobile" [showTermsLinks]="true" [menuItems]="mobileMenuItems()"> </app-menu>
  } @loading (after 150ms) {
  <app-skeleton-loader [lines]="5" type="menu-item"></app-skeleton-loader>
  }
  @placeholder {
  <div></div>
  }
</div>
}
@if (showItem() === 'mainDesktopNavMenu') {
@defer( when showItem() === 'mainDesktopNavMenu'; prefetch on idle) {
<app-header-menutray (close)="toggleShowItem(null)">
  <app-menu (click)="toggleShowItem(null)" menuType="headermenu-desktop-maps"
    [menuItems]="mainDesktopNavMenu()"></app-menu>
</app-header-menutray>
} @loading (after 150ms) {
<app-header-menutray>
  <app-skeleton-loader [lines]="6" type="menu-item"></app-skeleton-loader>
</app-header-menutray>
}
}

@if (showItem() === 'moreDesktopNavMenu') {
@defer (when showItem() === 'moreDesktopNavMenu'; prefetch on idle) {
<app-header-menutray (close)="toggleShowItem(null)">
  <app-menu (click)="toggleShowItem(null)" menuType="headermenu-desktop-more" [menuItems]="moreDesktopNavMenu"
    [showTermsLinks]="true" style="min-height: 723px;display: block;"></app-menu>
</app-header-menutray>
} @loading (after 150ms) {
<app-header-menutray>
  <app-skeleton-loader [lines]="5" type="menu-item"></app-skeleton-loader>
</app-header-menutray>
}
}

@if (showItem() === 'search') {
@defer (when showItem() === 'search'; prefetch on idle) {
<app-header-menutray (close)="toggleShowItem(null)" contentPosition="center">
  <app-header-search style="flex: 1 1 100%"></app-header-search>
</app-header-menutray>
} @loading (after 150ms) {
<app-header-menutray contentPosition="center">
  <app-skeleton-loader height="40px" [lines]="2" type="image"></app-skeleton-loader>
</app-header-menutray>
}
}
<!-- TAKE NOTE: Test dit met Chrome op responsive mode en iPhone SE, en zorg dat deze tekst slechts een regel is. Anders is gans de interface kaput -->

@if (showUpdateBanner()) {
<div class="bannernotification" [style.top.px]="this.headerDisplacement()">
  <div class="bannernotification__nieuw">NIEUW</div>
  @if (story(); as update) {
  <a (click)="close()" routerLink="/{{update.full_slug}}" class="bannernotification__link">
    {{update.name}}
  </a>
  }
  <svg height="20" width="20" class="icon bannernotification__close" (click)="close()">
    <use xlink:href="assets/icons/svg/all_svg_icons-9.svg#icon-x"></use>
  </svg>
</div>
}