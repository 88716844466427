import { Injectable } from '@angular/core';
import { UserParcelDetail } from '../interfaces/user-parcel.model';
import { environment } from 'environments/environment';
import { CoreModule } from '@app/core/core.module';

import { HttpClient } from '@angular/common/http';
import { UserParcelAttributes } from 'shared-types';


@Injectable({
    providedIn: CoreModule
})
export class UserParcelService {
    private apiLocation = environment.apiDomain + environment.apiUrl;

    constructor(
        private http: HttpClient,
    ) { }

    public listTags(organisationId: string) {
        return this.http.get<string[]>(this.apiLocation + `/organisation/${organisationId}/tag`);
    }

    public addParcels(organisationId: string, userParcels: Partial<UserParcelAttributes>[]) {
        return this.http.post<UserParcelAttributes[]>(this.apiLocation + `/organisation/${organisationId}/parcel`, userParcels);
    }

    public listParcels(params: {
        organisation_id: string;
        limit: string;
        offset: string;
        sortBy?: string;
        tags?: string[];
        searchTerm?: string;
        minSize?: string;
        maxSize?: string;
        bbox?: string;
    }) {
        return this.http.get<{
            count: number;
            limit: string;
            offset: string;
            rows: UserParcelAttributes[];
        }>(this.apiLocation + `/organisation/${params.organisation_id}/parcel`, {
            params: params
        });
    }


    public getParcel(id: string) {
        return this.http.get<UserParcelDetail>(this.apiLocation + `/user-parcel/${id}`);
    }

    public removeParcel(id: string) {
        return this.http.delete<void>(this.apiLocation + `/user-parcel/${id}`);
    }

    public updateParcel(id: string, updatedValues: Partial<UserParcelAttributes>) {
        return this.http.put<UserParcelAttributes>(
            this.apiLocation + `/user-parcel/${id}`,
            updatedValues
        );
    }

    public addRights(id: string) {
        return this.http.post<UserParcelAttributes>(
            this.apiLocation + `/user-parcel/${id}/rights`,
            {}
        );
    }

    public download(params: {
        format: string;
        organisation_id: string;
        ids: string[]
    }) {

        return this.http.post(this.apiLocation + `/organisation/${params.organisation_id}/user-parcel-download/${params.format}`,
            { ids: params.ids },
            {
                responseType: 'blob'
            });
    }

    public filtersToQueryParams(activeFilters: any) {
        return {
            ...(activeFilters?.tagsFilter?.length && { tags: activeFilters.tagsFilter.join(',') }),
            ...(activeFilters?.searchTerm && { searchTerm: activeFilters.searchTerm }),
            ...(activeFilters?.orderBy && { orderBy: activeFilters.orderBy }),
            ...(activeFilters?.hasRights !== null && { hasRights: activeFilters.hasRights }),
            ...(activeFilters?.IsnietNatuurlijkPersoon !== null && { IsnietNatuurlijkPersoon: activeFilters.IsnietNatuurlijkPersoon }),
            ...(activeFilters?.hasPhoneNumber !== null && { hasPhoneNumber: activeFilters.hasPhoneNumber }),
            ...(activeFilters?.hasEmail !== null && { hasEmail: activeFilters.hasEmail }),
            ...(activeFilters?.offset && { offset: activeFilters.offset }),
        };
    }

    public queryParamsToFilters(queryParams: any) {
        return {
            ...(!!queryParams.searchTerm && { searchTerm: queryParams.searchTerm }),
            ...(!!queryParams.orderBy && { orderBy: queryParams.orderBy }),
            ...(!!queryParams.offset && { offset: queryParams.offset }),
            ...(!!queryParams.tags && { tags: queryParams.tags }),
            ...(!!queryParams.hasRights && { hasRights: queryParams.hasRights }),
            ...(!!queryParams.IsnietNatuurlijkPersoon && { IsnietNatuurlijkPersoon: queryParams.IsnietNatuurlijkPersoon }),
            ...(!!queryParams.hasPhoneNumber && { hasPhoneNumber: queryParams.hasPhoneNumber }),
            ...(!!queryParams.hasEmail && { hasEmail: queryParams.hasEmail }),

        };
    }


}