import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action } from "@ngxs/store";
import { SetLastVisitedPage, UserRecordViewStateModel } from "./models/user-record-view.state.model";

@State<UserRecordViewStateModel>({
    name: 'userRecordView',
    defaults: {
        lastVisitedPage: 'table'
    }
})
@Injectable()
export class UserRecordViewState {
    // Return userParcels.
    @Selector([UserRecordViewState])
    static lastVisitedPage(state: UserRecordViewStateModel) {
        return state.lastVisitedPage;
    }

    constructor() { }


    @Action(SetLastVisitedPage)
    setLastVisitedPage(ctx: StateContext<UserRecordViewStateModel>, action: SetLastVisitedPage) {
        ctx.setState({ lastVisitedPage: action.payload.lastVisitedPage });
    }
s}



