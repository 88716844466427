import { Organisation, OrganisationBillingDetails } from "@app/core/interfaces/organisation.model";
import { PaymentTerm } from "@app/core/interfaces/payment-term.model";
import { SubscriptionType } from "@app/core/interfaces/subscription-type.model";
import { ApplicationSubscription } from "@app/core/interfaces/subscription.model";

export interface OrganisationStateModel extends Organisation {
}

export class SetOrganisation {
    static readonly type = '[Organisation] Set';
    constructor(public payload: Organisation) { }
}

export class UpdateOrganisation {
    static readonly type = '[Organisation] Update';
    constructor(public payload: Partial<Organisation>) { }
}

export class ClearOrganisation {
    static readonly type = '[Organisation] Clear';
}

export class UpdateBillingDetails {
    static readonly type = '[Organisation] Update Invoice Address';
    constructor(public payload: { organisation_id: string, updated_billing_details: Partial<OrganisationBillingDetails> }) { }
}

export class CancelSubscription {
    static readonly type = '[Organisation] CancelSubscription';
    constructor(public payload: { subscriptionId: string }) { }
}

export class AbortCancelSubscription {
    static readonly type = '[Organisation] AbortCancelSubscription';
    constructor(public payload: { subscriptionId: string }) { }
}

export class ChangePaymentTerm {
    static readonly type = '[Organisation] ChangePaymentTerm';
    constructor(public payload: { currentSubscription: ApplicationSubscription, futurePaymentTermName: string }) { }
}
export class UpgradeSubscription {
    static readonly type = '[Organisation] UpgradeSubscription';
    constructor(public payload: { currentSubscription: ApplicationSubscription, futureSubscriptionType: SubscriptionType, futurePaymentTerm: PaymentTerm }) { }
}

export class SetSubscriptionCancellationReason {
    static readonly type = '[Organisation] SetCancellationReason';
    constructor(public payload: { cancellationReason: string, subscriptionId: string, satisfied: boolean }) { }
}

