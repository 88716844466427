import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action, createSelector } from "@ngxs/store";
import { PaymentTerm } from "../interfaces/payment-term.model";
import { SubscriptionService } from "../services/subscription.service";
import { ListPaymentTerms } from "./models/payment-terms.state.model";
import { firstValueFrom } from "rxjs";

@State<PaymentTerm[]>({
    name: 'paymentTerms',
    defaults: []
})
@Injectable()
export class PaymentTermsState {
    // Return all payment terms
    @Selector([PaymentTermsState])
    static paymentTerms(state: PaymentTerm[]): PaymentTerm[] {
        return state.map(paymentTerm => {
            return {
                ...paymentTerm,
                displayName: getDisplayName(paymentTerm.name),
                confirmationDisplayName: getConfirmationDisplayName(paymentTerm.name)
            }
        });
    }

    // Dynamic selector notation for returning only 1 payment term
    static getPaymentTermByName(name: string) {
        return createSelector(
            [PaymentTermsState.paymentTerms],
            (paymentTerms: PaymentTerm[]) => {
                return paymentTerms.find(paymentTerm => paymentTerm.name === name);
            }
        );
    }

    constructor(private subscriptionService: SubscriptionService) { }

    @Action(ListPaymentTerms)
    async listPaymentTerms(ctx: StateContext<PaymentTerm[]>) {
        const paymentTerms = await firstValueFrom(this.subscriptionService.listPaymentTerms());
        ctx.setState(paymentTerms);
    }


}

function getDisplayName(paymentTermName: string) {
    const displayNameMap = {
        ['monthly']: 'Direct lid worden',
        ['yearly']: 'Direct lid worden'
    };

    return displayNameMap[paymentTermName];
}
function getConfirmationDisplayName(paymentTermName: string) {
    const displayNameMap = {
        ['monthly']: 'Maandlidmaatschap',
        ['yearly']: 'Jaarlidmaatschap'
    };

    return displayNameMap[paymentTermName];
}

