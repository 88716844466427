import { OrderItem, OrderItemCreationParams, Product } from "@app/core/interfaces/order.model";

export interface ShoppingCartStateModel {
    orderItems: OrderItemCreationParams[];
    email: string;
}
export class AddItemsToCart {
    static readonly type = '[ShoppingCart] AddItems';
    constructor(public payload: {orderItems: OrderItemCreationParams[], disableCartOpenTrigger?: boolean}) {
    }
}

export class RemoveItemFromCart {
    static readonly type = '[ShoppingCart] RemoveItem';
    constructor(public payload: {orderItem: OrderItemCreationParams}) {
    }
}

export class ResetCart {
    static readonly type = '[ShoppingCart] Reset';
    constructor() {}
}

export class RecalculateOrderItemPrices {
    static readonly type = '[ShoppingCart] Recalculate prices';
    constructor(public payload: {products: Product[]}) {}
}


export class AddEmailToOrder {
    static readonly type = '[ShoppingCart] Add Email';
    constructor(public payload: {email: string}) {}
}

